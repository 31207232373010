import React from "react";
import { Link, useNavigate } from "react-router-dom";
const logo = process.env.PUBLIC_URL + "../assets/images/LOGO.png";
export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <footer id="colophon" className="site-footer" role="contentinfo">
        <div className="wrap">
          <div className="row">
            <div className="col-sm-6  col-md-3 col-xs-12 mb-sm-32">
              <div className="custom-logo-link">
                <img src={logo} alt="" />
              </div>
              <p className="">
                Ananthadrishti Designs Architects grew exponentially by
                accepting every challenge that came their way.
              </p>
            </div>
            <div className="col-sm-6 col-md-3 col-xs-12 mb-sm-32 footer__address">
              <h4>Office</h4>
              <p className="">
                A - 321, park town complex, Aditya world city, Ghaziabad Uttar
                <br></br> Pradesh 201301, India
              </p>
              <p className="email__address">
                <a href="mailto:architectshwetabansal@gmail.com">
                  ananthadrishtidesigns@gmail.com
                </a>
                <br></br>

                <a href="tel:+919953609999">+91-99536-09999</a>
                {/* <br></br>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="tel:+917926752621">+91-79-26752621</a>
                <br></br>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="tel:+917926766639">+91-79-26766639</a>
                <br></br>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="tel:+917926766640">+91-79-26766640</a> */}
              </p>
            </div>
            <nav className="col-sm-2 col-md-2 col-sm-6 col-xs-6">
              <h4>Sitemap</h4>
              <ul id="footer-menu" className="footer__link">
                <li
                  id="menu-item-862"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <span className="cursor-pointer"> Home</span>
                </li>
                <li
                  id="menu-item-865"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <span className="cursor-pointer"> Studio</span>
                </li>
                <li
                  id="menu-item-864"
                  onClick={() => {
                    navigate("/practice");
                  }}
                >
                  <span className="cursor-pointer">Projects</span>
                </li>
                <li
                  id="menu-item-863"
                  onClick={() => {
                    navigate("/contact");
                  }}
                >
                  <span className="cursor-pointer"> Contact</span>
                </li>
              </ul>{" "}
            </nav>
            <nav className="col-sm-2 col-md-2 col-sm-6 col-xs-6">
              <h4>Follow on</h4>
              <ul className="footer__link">
                <li>
                  <Link to="https://www.facebook.com/profile.php?id=100069875432552&mibextid=ZbWKwL"
                  >
                    Facebook
                  </Link>
                </li>
                <li>
                <Link to="https://www.instagram.com/ananthadrishtidesigns/"
                  >
                   Instagram
                  </Link>
                </li>
                <li>
                  <span className="cursor-pointer"> Linkedin</span>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footer__bottom">
            <div className="site-info mb-sm-32 fadeInUp wow">
              Copyright © 2023 Ananthadrishti Designs - PrinceTech
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
