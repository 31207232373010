import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";


const project2 = process.env.PUBLIC_URL + "../assets/images/project1.1.jpg";
const project3 = process.env.PUBLIC_URL + "../assets/images/project1.2.jpg";
const project4 = process.env.PUBLIC_URL + "../assets/images/project1.3.jpg";
const project5 = process.env.PUBLIC_URL + "../assets/images/project1.4.jpg";


const ProjectDetails = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="../assets/images/project1.1.jpg"
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="../assets/images/project1.2.jpg"
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="../assets/images/project1.3.jpg"
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;<span className="cursor-pointer"  onClick={() => {
              navigate("/practice");
            }}>Projects</span>
        </p>

        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>Woodland Residence</h1>
            </div>
            <div className="col-md-8">
              When we dream of our ideal home, we often focus on the interior
              design and decoration. We imagine the perfect layout, the stylish
              furniture, and the cozy atmosphere. However, we often neglect the
              exterior of the house, which is just as important. <br />
              The exterior of the house is the first thing that people see when
              they visit or drive by. It is also the first impression that
              potential buyers will have of your home. A well-designed exterior
              can make your home look more inviting, stylish, and valuable.
              <br /> There are many factors to consider when designing the
              exterior of your home. The size and shape of your lot, the
              climate, and your personal style will all play a role in the final
              design. However, there are some general principles that can help
              you create a beautiful and functional exterior.
              <br /> One important principle is to choose materials that are
              durable and low-maintenance. You want your exterior to look its
              best for many years to come. Another important principle is to
              create a sense of balance and harmony. The different elements of
              your exterior should work together to create a cohesive look.
              <br /> Finally, don't be afraid to express your personal style
              through your exterior design. Choose colors, materials, and
              architectural features that reflect your taste. With a little
              planning and effort, you can create an exterior that you will love
              for years to come.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img
              className="w-full rounded image-container"
              alt="nike-air-shoe"
              src={project2}
            />
            <img
              className="w-full rounded image-container"
              alt="nike-air-shoe"
              src={project3}
            />
            <img
              className="w-full rounded image-container"
              alt="nike-air-shoe"
              src={project4}
            />
            <img
              className="w-full rounded image-container"
              alt="nike-air-shoe"
              src={project5}
            />
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default ProjectDetails;
