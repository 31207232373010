import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useScrollTop from "../hook/useScrollTop";

const profile = process.env.PUBLIC_URL + "../assets/images/image0.jpg";
const project2 = process.env.PUBLIC_URL + "../assets/images/project2.2.jpg";
const project3 = process.env.PUBLIC_URL + "../assets/images/project3.2.jpg";
const project4 = process.env.PUBLIC_URL + "../assets/images/project4.2.jpg";
const project5 = process.env.PUBLIC_URL + "../assets/images/project5.3.jpg";
const project6 = process.env.PUBLIC_URL + "../assets/images/project6.1.jpg";
const project7 = process.env.PUBLIC_URL + "../assets/images/project7.1.jpg";
const project8 = process.env.PUBLIC_URL + "../assets/images/project8.2.jpg";
const project9 = process.env.PUBLIC_URL + "../assets/images/project9.1.jpg";
const Home = () => {
  useScrollTop();

  //Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 5,
    margin: 0,
    autoplay: true,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  return (
    <div>
      <section className="section inner--banner">
        <div className="wrap">
          <p className="text-white mb-4">
            Architecture, Vastu Consultant, Construction, Interior, Landscape
          </p>
          <h1 className="text-white">
          Ananthadrishti Designs
            <br></br>your project with passion
          </h1>
        </div>
      </section>
      <div className="wrap">
        <p className="py-4">
         
        </p>
      </div>
      <section className="section section--founder pt-0">
        <div className="wrap">
          <div className="row">
            <div className="col-md-8 col-sm-12 mb-sm-32">
              <h2>Our Story</h2>
              <p className="harsh">
                In 2020, Ananthadrishti Designs was armed with a vision and a
                determination to make the architectural philosophy of Principal
                Architect AR. SHWETA BANSAL a reality. Being associated with the
                education sector, research in architecture, and many years of
                practical knowledge lead the mind towards a setup. The
                beginnings were tough, as the firm started with a small team in
                a small office but with great ideas.We have accomplished
                numerous projects with great enthusiasm and have several
                underway at any one time. The firm hopes to expand further in
                the region and intends to keep up with the latest trends in the
                industry, making it the firm of choice when it comes to
                architecture and interior design.
              </p>
            </div>
            <div className="col-md-4 col-sm-12">
              <img alt="site" className="img-fluid" src={profile} />
            </div>
          </div>
        </div>
      </section>
      <section>
        <OwlCarousel
          // id="customer-testimonoals"
          className="owl-carousel owl-theme"
          {...options}
        >
          <div className="item">
            <div className="shadow-effect">
              <img alt="site" src={project2} />
            </div>
          </div>
          <div className="item">
            <div className="shadow-effect">
              <img alt="site" src={project3} />
            </div>
          </div>
          <div className="item">
            <div className="shadow-effect">
              <img alt="site" src={project4} />
            </div>
          </div>
          <div className="item">
            <div className="shadow-effect">
              <img alt="site" src={project5} />
            </div>
          </div>
          <div className="item">
            <div className="shadow-effect">
              <img alt="site" src={project6} />
            </div>
          </div>
          <div className="item">
            <div className="shadow-effect">
              <img alt="site" src={project7} />
            </div>
          </div>
          <div className="item">
            <div className="shadow-effect">
              <img alt="site" src={project8} />
            </div>
          </div>
          <div className="item">
            <div className="shadow-effect">
              <img alt="site" src={project9} />
            </div>
          </div>
        </OwlCarousel>
      </section>
      <section className="section section--services">
        <div className="wrap">
          <h2>What we do</h2>
          <p className="info"></p>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-4 col-sm-6 col-xs-12 mb-sm-32 mb-3">
              <div className="services__block">
                <img alt="site" className="img-fluid" src="../assets/images/image1.png" />
                <div className="overlay">
                  <span className="arrow__right"></span>
                  <div className="page"></div>
                </div>
                <h4>Architecture</h4>
                <p>
                  Architecture is not only based of steel and bricks. Its beyond
                  what we know, beyond what we think.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-sm-32 mb-3">
              <div className="services__block">
                <img alt="site" className="img-fluid" src="../assets/images/image2.jpg" />
                <div className="overlay">
                  <span className="arrow__right"></span>
                  <div className="page"></div>
                </div>
                <h4>Interior Design</h4>
                <p>
                  Every room needs a touch of color, texture, light, just as it
                  needs one antique piece.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-sm-32 mb-3">
              <div className="services__block">
                <img alt="site" className="img-fluid" src="../assets/images/image3.png" />
                <div className="overlay">
                  <span className="arrow__right"></span>
                  <div className="page"></div>
                </div>
                <h4>Landscape Design</h4>
                <p>
                  A building is a tangible structure that must match the
                  landscape that surrounds it.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-sm-32 mb-3">
              <div className="services__block">
                <img alt="site" className="img-fluid" src="../assets/images/image4.jpg" />
                <div className="overlay">
                  <span className="arrow__right"></span>
                  <div className="page"></div>
                </div>
                <h4>Construction </h4>
                <p>
                  It is not just the appearance of the structure that we should
                  be looking at; its structure will stand the test of time.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-xs-12 mb-sm-32 mb-3">
              <div className="services__block">
                <img alt="site" className="img-fluid" src="../assets/images/vastu_consultant.png" />
                <div className="overlay">
                  <span className="arrow__right"></span>
                  <div className="page"></div>
                </div>
                <h4>Vastu Consultant </h4>
                <p>crystal which makes your “house” an ideal “abode.”</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--vision pt-0">
        <div className="wrap">
          <div className="row">
            <div className="col-md-3 col-xs-12">
              <h2>
                <span>
                  <span>Our</span> Vision
                </span>
              </h2>
            </div>
            <div className="col-md-9 col-xs-12">
              <p>
                We pride ourselves on creating appropriate spaces where
                residents can truly relax in a safe environment. At the
                intersection of architecture, landscape, and the city, our housing.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
