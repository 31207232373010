import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const project1 = process.env.PUBLIC_URL + "../assets/images/project5.1.jpg";
const project2 = process.env.PUBLIC_URL + "../assets/images/project5.2.jpg";
const project3 = process.env.PUBLIC_URL + "../assets/images/project5.3.jpg";

const Cookhouse = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project2}
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project3}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project2}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/practice");
            }}
          >
            Projects
          </span>
        </p>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>Cookhouse</h1>
            </div>
            <div className="col-md-8">
              Our modular kitchens are versatile and adaptable, tailored to
              align seamlessly with your financial considerations and the
              available space in your home. Our range of kitchen interior
              designs is designed to accommodate various kitchen shapes,
              ensuring a customized solution for every need. Whether you
              envision an L-shaped kitchen, a U-shaped kitchen, or the inviting
              expanse of an open kitchen, our primary objective is to craft the
              ideal culinary space within your residence.
              <br />
              At our core is the commitment to make your kitchen dreams a
              reality without straining your budget. We understand that each
              kitchen layout has its unique advantages and challenges, and we
              embrace the opportunity to harness these elements to your
              advantage. Our expertise lies in harnessing every inch of
              available space, maximizing both functionality and aesthetics to
              create kitchens that are not only practical but also a source of
              pride within your home.
              <br />
              In this endeavor, we merge creativity and practicality to deliver
              kitchen designs that not only meet your expectations but also
              exceed them. Our modular kitchens are a testament to our
              dedication to transforming spaces into functional, beautiful, and
              inviting culinary hubs, where every meal becomes an enjoyable
              experience.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            // className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project1}
            />
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project2}
            />
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project3}
            />
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default Cookhouse;
