import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const project1 = process.env.PUBLIC_URL + "../assets/images/project7.1.jpg";

const Dreamsville = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/practice");
            }}
          >
            Projects
          </span>
        </p>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>Dreamsville</h1>
            </div>
            <div className="col-md-8">
              The blueprint for your bedroom's lighting design possesses the
              remarkable ability to shape and sculpt your emotional responses
              and moods. It's akin to wielding a magic wand, capable of
              seamlessly transitioning your ambiance from cheery to romantic or
              from weary to intimate with a simple flick of a switch. The
              transformative power of well-thought-out bedroom lighting cannot
              be understated; it holds the potential to create an atmosphere
              that resonates harmoniously with your inner feelings and desires.
              <br />
              What's truly remarkable is that achieving a positive impact on
              your mood need not be an arduous endeavor. The era of Herculean
              efforts is behind us. In today's world of interior design, a
              plethora of lighting options exists, allowing you to effortlessly
              craft a bedroom lighting design plan that caters to your emotional
              well-being.
              <br />
              Whether you seek the soothing embrace of soft, warm hues for
              relaxation or the vibrant radiance of task lighting to invigorate
              your spirits, the choices are abundant. Each lighting element can
              be strategically chosen and positioned to serve as a catalyst for
              creating your desired ambiance. The key lies in seamlessly
              blending aesthetics with functionality, ensuring that your bedroom
              becomes a sanctuary where light not only illuminates but also
              uplifts, enhancing your mood while maintaining the perfect balance
              of illumination.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            // className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project1}
            />
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default Dreamsville;
