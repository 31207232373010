import React from "react";
import { useNavigate } from "react-router-dom";
import useScrollTop from "../hook/useScrollTop";

const project1 = process.env.PUBLIC_URL + "../assets/images/project1.1.jpg";
const project2 = process.env.PUBLIC_URL + "../assets/images/project2.1.jpg";
const project3 = process.env.PUBLIC_URL + "../assets/images/project3.1.jpg";
const project4 = process.env.PUBLIC_URL + "../assets/images/project4.1.jpg";
const project5 = process.env.PUBLIC_URL + "../assets/images/project5.1.jpg";
const project6 = process.env.PUBLIC_URL + "../assets/images/project6.1.jpg";
const project7 = process.env.PUBLIC_URL + "../assets/images/project7.1.jpg";
const project8 = process.env.PUBLIC_URL + "../assets/images/project8.1.jpg";
const project9 = process.env.PUBLIC_URL + "../assets/images/project9.1.jpg";

const Practice = () => {
  useScrollTop();
  const navigate = useNavigate();
  return (
    <div>
      <section className="section inner--banner section--portfolios casestudy-banner mb-80">
        <div className="wrap d-flex justify-content-between">
          <h1 className="text-white">Our Works</h1>
          <div className="row portfolios__row row--items-center">
            <div className=" col-md-8 col-xs-12 mb-xs-32"></div>
            <div className=" col-md-4 mb-3 col-xs-12">
              <ul className="catheader__list">
                <li>
                  <p style={{ fontSize: "1.5em" }}>Projects</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="wrap mt-5 mb-5">
        <p className="wrap">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;<span>Projects</span>
        </p>
        <div className="row mb-3">
          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project1} alt="nike-air-shoe" />
                </div>

                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">Woodland Residence</h3>
                  <p className="size">
                    When it comes to designing the home of our dreams, we
                    usually tend to think about the interior design and
                    decoration and overlook the fact that at the end of the day,
                    it is the exterior of the house that we arrive at. Remember,
                    it's the facade that friends, neighbors and even strangers
                    see when they visit you or just pass by. The spectacular
                    exterior is sure to stand out and reflect the overall style
                    of the home.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/project-details");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project2} alt="nike-air-shoe" />
                </div>

                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">The First Kick</h3>
                  <p className="size">
                    This interior is based on a football theme for a 6-year-old
                    boy who was a big fan of football. The room is designed
                    keeping in mind the present with future scenarios of the
                    user and his family.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/the-first-kick");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project3} alt="nike-air-shoe" />
                </div>

                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">Aesthetic Beautification</h3>
                  <p className="size">
                    Interior design is truly a magical spell. Beauty is not for
                    people alone, but elegantbut an elegant trendy attire is
                    essential for beauty parlors and alsoparlours also to invite
                    more and more customers. From deciding mirrors to furniture
                    and storage compartments, we reproduce every little
                    definition by the preference of our customers.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/aesthetic-beautification");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project4} alt="nike-air-shoe" />
                </div>

                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">The Moody Moon</h3>
                  <p className="size">
                    From straight and sharp interior design, the vocabulary now
                    registers a noticeable shift to the subtle end of the
                    spectrum, dominated by rounded elements and curves. Smooth
                    curves, sensual upholstered elements, elegant scalloped
                    edges, striking rounded shapes and undulating planes now
                    bring an easy, relaxed atmosphere to everything from
                    furniture to lighting to accessories.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/the-moody-moon");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project5} alt="nike-air-shoe" />
                </div>

                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">Cookhouse</h3>
                  <p className="size">
                    All of our modular kitchens can be designed to fit your
                    budget and available space. We have kitchen interior designs
                    to suit all shapes: whether it's an L-shaped kitchen, a
                    U-shaped kitchen or an open kitchen - our goal is to create
                    the perfect kitchen for your home.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/cook-housen");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project6} alt="nike-air-shoe" />
                </div>
                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">Blushing Beauty</h3>
                  <p className="size">
                    Parlor design house is a typical studio dedicated to styling
                    and interior design. Beauty salon interior design salon
                    decoration interior design decoracion de salon belleza
                    amazing salon designs to inspire you.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/blushing-beauty");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project7} alt="nike-air-shoe" />
                </div>

                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">Dreamsville</h3>
                  <p className="size">
                    Your bedroom lighting design plan can influence your
                    emotional responses and moods, changing them from cheerful
                    to romantic and exhausted to intimate with the flick of a
                    switch. Positively influencing your mood without
                    compromising the amount of light is no longer a Herculean
                    task. With many lighting options available, you can quickly
                    get a bedroom lighting design plan.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/dreamsville");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project8} alt="nike-air-shoe" />
                </div>

                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">Working Corner</h3>
                  <p className="size">
                    India is changing the way you look at the typical work
                    environment. The designers focused on spatial organization
                    rather than cubic office displays. The design approach only
                    leans towards the idea of ​​increasing the creative energy
                    in working professionals. The use of colors and interior
                    details play a major role in designing the smallest corner
                    of the office space inside the sliding doors.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/working-corner");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="container">
              <div className="card">
                <div className="imgBx">
                  <img src={project9} alt="nike-air-shoe" />
                </div>

                <div className="contentBx p-1 text-center">
                  <h3 className="m-0 p-0 mt-2">Tahitian Pearls</h3>
                  <p className="size">
                    Your bedroom lighting design plan can influence your
                    emotional responses and moods, changing them from cheerful
                    to romantic and exhausted to intimate with the flick of a
                    switch. Positively influencing your mood without
                    compromising the amount of light is no longer a Herculean
                    task. With many lighting options available, you can quickly
                    get a bedroom lighting design plan.
                  </p>
                  <p
                    className="read"
                    onClick={() => {
                      navigate("/tahitian-pearls");
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Practice;
