import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const project1 = process.env.PUBLIC_URL + "../assets/images/project2.5.jpg";
const project2 = process.env.PUBLIC_URL + "../assets/images/project2.1.jpg";
const project3 = process.env.PUBLIC_URL + "../assets/images/project2.2.jpg";
const project4 = process.env.PUBLIC_URL + "../assets/images/project2.3.jpg";
const project5 = process.env.PUBLIC_URL + "../assets/images/project2.4.jpg";

const TheFirstKick = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project2}
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project3}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project4}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project5}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/practice");
            }}
          >
            Projects
          </span>
        </p>

        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>The First Kick</h1>
            </div>
            <div className="col-md-8">
              The interior of this room is centered around a football-themed
              concept, specially created for a 6-year-old boy who possesses an
              immense love for the sport. The design of the room has been
              thoughtfully executed, taking into consideration both the
              immediate and long-term requirements of the young occupant and his
              family. <br />
              The football theme permeates every aspect of the room's decor and
              layout, from the vibrant wall colors resembling a football pitch
              to the football-shaped furniture and decor elements that adorn the
              space. The goal was to create an environment that not only
              reflects the boy's current passion but also provides a versatile
              and adaptable setting that can evolve as he grows older.
              <br /> With the inclusion of durable and timeless furnishings, the
              room is designed to withstand the wear and tear of an active young
              child while also allowing for easy updates in the future as his
              interests and preferences change. This thoughtful approach ensures
              that the room remains a cherished and functional space for years
              to come, catering to the evolving needs and interests of the young
              football enthusiast and his family.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            // className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img className="w-full rounded image-container  cont-height" alt="nike-air-shoe" src={project1} />
            <img className="w-full rounded image-container  cont-height" alt="nike-air-shoe" src={project2} />
            <img className="w-full rounded image-container  cont-height" alt="nike-air-shoe" src={project3} />
            <img className="w-full rounded image-container  cont-height" alt="nike-air-shoe" src={project4} />
            <img className="w-full rounded image-container sp-wdth cont-height" alt="nike-air-shoe" src={project5} />
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default TheFirstKick;
