import React from "react";
import { Route, Routes } from "react-router-dom";
import { Header } from "../compunent/Header";
import { Footer } from "../compunent/Footer";
import Home from "../pages/Home";
import Practice from "../pages/Practice";
import Contact from "../pages/Contact";
import SketchBook from "../pages/SketchBook";
import ProjectDetails from "../pages/projectDetails/ProjectDetails";
import TheFirstKick from "../pages/projectDetails/TheFirstKick";
import AestheticBeautification from "../pages/projectDetails/AestheticBeautification";
import TheMoodyMoon from "../pages/projectDetails/TheMoodyMoon";
import Cookhouse from "../pages/projectDetails/Cookhouse";
import WorkingCorner from "../pages/projectDetails/WorkingCorner";
import BlushingBeauty from "../pages/projectDetails/BlushingBeauty";
import Dreamsville from "../pages/projectDetails/Dreamsville";
import TahitianPearls from "../pages/projectDetails/TahitianPearls";

const AppRouter = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/practice" element={<Practice />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sketch-book" element={<SketchBook />} />
        <Route path="/project-details" element={<ProjectDetails />} />
        <Route path="/the-first-kick" element={<TheFirstKick />} />
        <Route path="/aesthetic-beautification" element={<AestheticBeautification />} />
        <Route path="/the-moody-moon" element={<TheMoodyMoon />} />
        <Route path="/cook-housen" element={<Cookhouse />} />
        <Route path="/working-corner" element={<WorkingCorner />} />
        <Route path="/blushing-beauty" element={<BlushingBeauty />} />
        <Route path="/dreamsville" element={<Dreamsville />} />
        <Route path="/tahitian-pearls" element={<TahitianPearls />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default AppRouter;
