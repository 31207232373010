import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const project1 = process.env.PUBLIC_URL + "../assets/images/project6.1.jpg";

const BlushingBeauty = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/practice");
            }}
          >
            Projects
          </span>
        </p>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>Blushing Beauty</h1>
            </div>
            <div className="col-md-8">
              Parlor Design House represents a quintessential studio
              specializing in the art of styling and interior design. Our
              expertise extends to the realm of beauty salon interiors, where we
              bring forth innovative and captivating designs. With a focus on
              salon decoration and interior design, we embark on a creative
              journey that elevates the aesthetics of every space we touch.
              <br />
              Our salon interior designs are nothing short of extraordinary,
              bearing witness to our dedication to inspiring and transforming
              your beauty haven. We believe in crafting salon spaces that not
              only captivate the eye but also enhance the overall experience for
              both patrons and professionals. With an array of amazing salon
              designs, we endeavor to spark your imagination and fuel your
              passion for creating a salon that is as unique as it is inviting.
              <br />
              At Parlor Design House, we redefine the art of salon interiors,
              turning each space into a canvas where beauty meets innovation,
              style meets functionality, and every detail reflects the essence
              of your vision.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            // className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project1}
            />
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default BlushingBeauty;
