import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const logo = process.env.PUBLIC_URL + "../assets/images/LOGO_AD.png"


export const Header = () => {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = () => {
    const header = document.querySelector(".main-header");
    const scrollTop = window.scrollY;
    scrollTop > 70
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  const navigate = useNavigate();
  return (
    <header>
 
    <Navbar expand="lg" className=" header p-3 main-header">
      
        <div className="hp">
        {/* <Link to='/'> */}
         <img className="logo cursor-pointer" onClick={()=>navigate("/")} src={logo} alt="logo" />
          {/* </Link> */}
          </div>
       
        <Navbar.Toggle aria-controls="navbarScroll" />
      
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className=" nav"
            // style={{ width: '500px' }}
            navbarScroll
          >
           <Link className="nav-link" to='/'>Studio</Link>
         <Link className="nav-link" to='/practice'>Project</Link>
            <Link className="nav-link" to='/contact'>contact</Link>
           
          </Nav>
         
        </Navbar.Collapse>
    
    </Navbar>
   </header> 
  );
};
