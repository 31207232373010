import React from "react";
import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import image1 from "../assets/images/image1.png";
import image2 from "../assets/images/image0.jpg";

const SketchBook = () => {
  return (
    <div>
      <section className="section inner--banner">
        <div className="wrap">
          <h1 className="text-white mb-4">Sketchbook</h1>
          <p className="text-white">
            A Carrying a sketchbook
            <br></br>and I love it.
          </p>
        </div>
      </section>
      <SlideshowLightbox
        className="flex"
        showControls={true}
        // slideshowInterval={5000}
        fullScreen={true}
      >
        <img alt="site" className="w-full rounded" width={100} height={100} src={image1} />
        <img alt="site" className="w-full rounded" width={100} height={100} src={image2} />
      </SlideshowLightbox>
      {/* <section className="section section--projects">
        <div className="wrap">
          <div className="row">
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_picture025-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_picture025-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    fetchpriority="high"
                   
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_SKM_C224e16020613422_0001-a-1.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_SKM_C224e16020613422_0001-a-1-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_DSC_0196.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_DSC_0196-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_Picture-031-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_Picture-031-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_SKM_C224e16020613411_0001-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_SKM_C224e16020613411_0001-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                   
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_DSC_0199.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_DSC_0199-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_20-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_20-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                   
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_Picture-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_Picture-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_13-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_13-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_DSC_0200.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_DSC_0200-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                   
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_3-2-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_3-2-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                   
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_15-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_15-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_18-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_18-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_23-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_23-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_27-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_27-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_picture021-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_picture021-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
            <div className=" col-md-4 mb-40">
              <div className="projects__image mb-16">
                <a
                  data-fancybox="gallery"
                  href="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_12-a.jpg"
                >
                  <img
                    
                    src="https://www.hpa.co.in/wp-content/uploads/2021/10/tn_12-a-340x260.jpg"
                    className="attachment-art-thumbnail size-art-thumbnail wp-post-image"
                    alt=""
                    decoding="async"
                    loading="lazy"
                    
                  />
                </a>
              </div>
            </div>
          </div>
         
        </div>
      </section> */}
    </div>
  );
};

export default SketchBook;
// import React from 'react';
// import image1 from "../assets/images/4.png"
// import image2 from "../assets/images/2.png"
// import "lightbox.js-react/dist/index.css";
// import { SlideshowLightbox } from "lightbox.js-react";

// const ImageLab = () => {

//     return (
//         <div>ImageLab
//             <SlideshowLightbox className="flex"
//                 showControls={true}
//                 slideshowInterval={5000}
//                 fullScreen={true}
//             >

//                 <img
//                     className="w-full rounded"
//                     width={100}
//                     height={100}
//                     src={image1}
//                 />
//                 <img
//                     className="w-full rounded"
//                     width={100}
//                     height={100}
//                     src={image2}
//                 />

//             </SlideshowLightbox>
//         </div>
//     );
// }

// export default ImageLab;
