import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const project1 = process.env.PUBLIC_URL + "../assets/images/project8.1.jpg";
const project2 = process.env.PUBLIC_URL + "../assets/images/project8.2.jpg";
const project3 = process.env.PUBLIC_URL + "../assets/images/project8.3.jpg";

const WorkingCorner = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project2}
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project3}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project2}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/practice");
            }}
          >
            Projects
          </span>
        </p>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>Working Corner</h1>
            </div>
            <div className="col-md-8">
              India is leading a transformative shift in the perception of
              conventional workspaces. Instead of adhering to traditional cubic
              office layouts, designers are placing a strong emphasis on spatial
              organization. This innovative design approach is centered around
              the concept of enhancing the creative vitality of working
              professionals. It revolves around the idea that a well-designed
              workspace can serve as a catalyst for productivity, collaboration,
              and inspiration.
              <br />
              One of the striking facets of this evolving trend is the strategic
              use of colors and interior details. Every nook and cranny within
              office spaces, even those concealed behind sliding doors, is
              meticulously designed to evoke a specific ambiance. These colors
              and details are carefully selected to create an atmosphere that
              resonates with the ethos and culture of the organization. They are
              chosen not merely for aesthetic appeal but as integral components
              that influence mood and creativity.
              <br />
              In this progressive approach to workspace design, the physical
              environment becomes a canvas for fostering innovation and a sense
              of community. It's an acknowledgment that the workplace is more
              than just a location for tasks; it is a dynamic arena where ideas
              flourish, collaborations thrive, and individuals are inspired to
              do their best work.
              <br />
              India's evolving work environment is a testament to the power of
              thoughtful design, where the arrangement of space, color schemes,
              and intricate interior elements converge to create an environment
              that nurtures both the mind and the imagination of working
              professionals.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            // className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project1}
            />
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project2}
            />
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project3}
            />
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default WorkingCorner;
