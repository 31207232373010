import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const project1 = process.env.PUBLIC_URL + "../assets/images/project3.1.jpg";
const project2 = process.env.PUBLIC_URL + "../assets/images/project3.2.jpg";

const AestheticBeautification = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project2}
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project2}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/practice");
            }}
          >
            Projects
          </span>
        </p>

        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>Aesthetic Beautification</h1>
            </div>
            <div className="col-md-8">
              Interior design possesses a captivating enchantment, a magical
              spell that weaves its transformative power not only in personal
              spaces but also within the hallowed halls of beauty parlors. In
              the realm of beauty, an elegant and trendy ambiance is a requisite
              charm, a siren song beckoning customers to step inside and indulge
              in a world of pampering and self-care. This allure extends beyond
              mere aesthetics; it is an art form, a meticulous crafting of every
              nook and cranny that resonates with the unique desires and
              preferences of our clientele. <br />
              From the very choice of mirrors that reflect both beauty and
              confidence to the selection of furniture that marries comfort and
              style, our design philosophy is rooted in precision and
              perfection. Each element is curated with a discerning eye, and
              each decision is made with the goal of not just meeting but
              exceeding expectations. Storage compartments are more than mere
              spaces; they are repositories of organization and functionality,
              seamlessly integrated into the design tapestry.
              <br />
              Our mission is to encapsulate beauty in every facet, to
              orchestrate a symphony of design that transforms ordinary spaces
              into extraordinary showcases. Whether it's the sumptuous luxury of
              seating arrangements, the harmonious interplay of colors, or the
              subtle nuances that ignite the senses, we leave no stone unturned.
              In the world of beauty parlors, our designs are the enchanting
              spell that entices, captivates, and leaves an indelible
              impression, inviting patrons to return again and again. It is a
              testament to the artistry of interior design—a symphony of beauty
              and elegance that stands as an enduring testament to our craft.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            // className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project1}
            />
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project2}
            />
            {/* <img className="w-full rounded image-container  cont-height" alt="nike-air-shoe" src={project3} />
            <img className="w-full rounded image-container  cont-height" alt="nike-air-shoe" src={project4} />
            <img className="w-full rounded image-container sp-wdth cont-height" alt="nike-air-shoe" src={project5} /> */}
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default AestheticBeautification;
