import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const project1 = process.env.PUBLIC_URL + "../assets/images/project4.1.jpg";
const project2 = process.env.PUBLIC_URL + "../assets/images/project4.2.jpg";
const project3 = process.env.PUBLIC_URL + "../assets/images/project4.3.jpg";
const project4 = process.env.PUBLIC_URL + "../assets/images/project4.4.jpg";
const project5 = process.env.PUBLIC_URL + "../assets/images/project4.5.jpg";

const TheMoodyMoon = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project2}
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project3}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project4}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project5}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/practice");
            }}
          >
            Projects
          </span>
        </p>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>The Moody Moon</h1>
            </div>
            <div className="col-md-8">
              The lexicon of interior design is undergoing a remarkable
              transformation, shifting from the realm of straight lines and
              sharp angles to embrace the more nuanced end of the design
              spectrum. This evolution is characterized by a prevailing
              influence of rounded elements and graceful curves, ushering in a
              sense of serenity and softness. It is a departure from the stark
              and angular aesthetics of the past, welcoming a new era of design
              marked by a harmonious marriage of form and function. <br />
              In this gentle evolution, we find the emergence of smooth, flowing
              curves that infuse spaces with an innate sense of fluidity and
              grace. Sensual upholstered elements invite tactile exploration,
              evoking comfort and a desire to linger. The introduction of
              elegant scalloped edges lends a touch of whimsy and
              sophistication, breaking away from the rigidity of straight lines.
              <br />
              Moreover, striking rounded shapes and undulating planes redefine
              the visual landscape, introducing an organic quality that
              resonates with nature's inherent beauty. This design ethos extends
              its embrace to encompass everything from furniture to lighting to
              accessories, creating a cohesive and inviting atmosphere that
              effortlessly captivates the senses.
              <br />
              In this shift towards rounded elements and curves, the world of
              interior design finds a newfound sense of ease and relaxation, an
              aesthetic that transcends trends and endures as a timeless
              expression of elegance and comfort. It is a testament to the
              ever-evolving nature of design, where the language of beauty is
              rewritten with each graceful curve and rounded contour.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            // className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project1}
            />
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project2}
            />
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project3}
            />
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project4}
            />
            <img
              className="w-full rounded image-container sp-wdth cont-height"
              alt="nike-air-shoe"
              src={project5}
            />
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default TheMoodyMoon;
