import React from "react";
import useScrollTop from "../hook/useScrollTop";

const Contact = () => {
  useScrollTop();
  return (
    <div className="contact">
      <section className="section section--contact inner--banner pb-0">
        <div className="wrap">
          <div className="contact__address">
            <h1 className="text-white">Contact Us</h1>
            <div className="row">
              <div className="col-md-5 col-sm-12 mb-sm-32">
                <p className="text-dark">
                Ananthadrishti Designs Architects grew exponentially by accepting every challenge
                  that came their way
                </p>
              </div>
              <div className="col-md-7 col-sm-12">
                <div className="row">
                  <div className="col-6 col-xs-12 mb-xs-32">
                    <p className="text-white">
                      A - 321, Park Town Complex, Aditya World City, Ghaziabad
                      Uttar<br></br> Pradesh 201301, India
                    </p>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <p className="email__address text-white">
                      <a
                        className="text-white"
                        href="mailto:ananthadrishtidesigns@gmail.com"
                      >
                        {" "}
                        ananthadrishtidesigns@gmail.com
                      </a>
                      <br></br>
                      <a className="text-white" href="tel:+919953609999">+91-99536-09999</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact__map">
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7002.552378388211!2d77.4803739155954!3d28.651447852211042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf3a4d3e503d9%3A0x4bae765955c98d20!2sPark%20Town%20Commercial!5e0!3m2!1sen!2sin!4v1693298092117!5m2!1sen!2sin"
              width="100%"
              height="450"
              title="park-title"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="section section--contactform">
        <div className="wrap">
          <div className="row">
            <div className="col-md-6 col-xs-12 mb-xs-32">
              <h3>Would you like to discuss a project?</h3>
              <p>So you better ensure the meeting is worth it!</p>
              <div className="contact__form">
                <div className="wpcf7 js" id="wpcf7-f8-o1" lang="en-US" dir="ltr">
                  <div className="screen-reader-response">
                    <p role="status" aria-live="polite" aria-atomic="true"></p>{" "}
                    <ul></ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-xs-12"></div>
            <div className="col-md-4 col-xs-12">
              <h3>Want to work with us?</h3>
              <p>
                Share your portfolio and intent with us on <br></br>
                <a
                  className="text-dark email"
                  href="mailto:ananthadrishtidesigns@gmail.com"
                >
                  ananthadrishtidesigns@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
