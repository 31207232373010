import React from "react";
import Carousel from "react-bootstrap/Carousel";
import useScrollTop from "../../hook/useScrollTop";
import { useNavigate } from "react-router-dom";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const project1 = process.env.PUBLIC_URL + "../assets/images/project9.1.jpg";

const TahitianPearls = () => {
  const navigate = useNavigate();
  useScrollTop();
  return (
    <div>
      <section>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="First slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Second slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 responsive-image"
              src={project1}
              alt="Third slide"
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
      <section className="section wrap">
        <p className="py-4">
          <span
            onClick={() => {
              navigate("/");
            }}
            className="cursor-pointer"
          >
            Home
          </span>
          &nbsp; /&nbsp;
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/practice");
            }}
          >
            Projects
          </span>
        </p>
        <div className="mb-3">
          <div className="row">
            <div className="col-md-4">
              <h1>Tahitian Pearls</h1>
            </div>
            <div className="col-md-8">
              The intricacies of your bedroom's lighting scheme possess a
              remarkable capacity to sway and mold your emotional reactions and
              overall moods. It's akin to wielding a magical instrument, capable
              of orchestrating an enchanting transformation from an atmosphere
              of cheerfulness to one dripping with romance or from a state of
              exhaustion to an ambiance that exudes intimacy—all with a mere
              flick of a switch. The profound influence of a thoughtfully
              devised bedroom lighting design should not be underestimated; it
              holds the power to craft an environment that resonates
              harmoniously with your innermost sentiments and aspirations.
              <br />
              What's truly extraordinary is that enhancing your emotional state
              without compromising on the illumination levels has become a
              significantly more manageable undertaking. The days of arduous
              endeavors are long gone. In the contemporary realm of interior
              design, a vast array of lighting alternatives awaits, affording
              you the opportunity to effortlessly construct a bedroom lighting
              design blueprint that caters to your psychological well-being.
              <br />
              Whether you yearn for the serene embrace of soft, warm tones to
              induce relaxation or the vibrant radiance of task-oriented
              lighting to invigorate your spirits, the choices abound. Each
              lighting element can be methodically selected and strategically
              placed, serving as a catalyst for crafting your desired ambiance.
              The crux of the matter is the seamless fusion of aesthetics with
              functionality, ensuring that your bedroom becomes a sanctum where
              light not only illuminates but also elevates, amplifying your mood
              while preserving the delicate equilibrium of luminosity.
            </div>
          </div>
        </div>
      </section>
      <section style={{ textAlign: "center" }} className="mb-5">
        <h1>Project Images</h1>
        <div className="wrap">
          <SlideshowLightbox
            // className="flex"
            showControls={true}
            fullScreen={true}
            slideshowInterval={9000}
          >
            <img
              className="w-full rounded image-container  cont-height"
              alt="nike-air-shoe"
              src={project1}
            />
          </SlideshowLightbox>
        </div>
      </section>
    </div>
  );
};

export default TahitianPearls;
